import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import StringMask from 'string-mask'

import api from '../services/api'
import ItemEstrutura from '../components/ItemEstrutura'
import { FormatarData } from '../components/FormatarData'
import { FiltroCaracteristica } from '../components/FiltroCaracteristica'

export default function Unidade() {
  const { id } = useParams()
  const [unidade, setUnidade] = useState(false)
  const [filhos, setFilhos] = useState([])
  const [search, setSearch] = useState(null)
  const [caracteristicas, setCaracteristicas] = useState(null)
  const [exibirEstrutura, setExibirEstrutura] = useState(false)

  const maskCnpj = new StringMask('00.000.000/0000-00')
  const maskCep = new StringMask('00.000-000')

  const loadUnidade = useCallback(async () => {
    const retorno = await api.get(`/unidades/${id}/detalhes`)
    setUnidade(retorno.data)
  }, [id])

  useEffect(() => {
    setSearch('')
    loadUnidade()
  }, [loadUnidade])

  const loadFilhos = useCallback(async () => {
    const retorno = await api.get(
      `/unidades/${id}/estrutura-direta?s=${search}&${caracteristicas}`
    )
    setFilhos(retorno.data)
    if (retorno.data) setExibirEstrutura(true)
  }, [id, search, caracteristicas])

  useEffect(() => {
    loadFilhos()
  }, [loadFilhos])

  return (
    <>
      {unidade && (
        <>
          <div className="tag is-orange is-uppercase has-text-weight-bold mb-2">
            Unidade
          </div>
          {unidade.sigla && <> {unidade.sigla}</>}
          <h1 className="title is-4 mb-0">{unidade.nome}</h1>
          <div className="mt-1">
            {unidade?.gestora && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in unidade ? 'orange' : 'dark'
                }`}
              >
                Gestora
              </span>
            )}
            {unidade?.orcamentaria && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in unidade ? 'orange' : 'dark'
                }`}
              >
                Orçamentária
              </span>
            )}
            {unidade?.administrativa && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in unidade ? 'orange' : 'dark'
                }`}
              >
                Administrativa
              </span>
            )}
            {unidade?.colegiada && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in unidade ? 'orange' : 'dark'
                }`}
              >
                Colegiada
              </span>
            )}
            {unidade?.staff && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in unidade ? 'orange' : 'dark'
                }`}
              >
                Staff
              </span>
            )}
          </div>
          {'tipo_normatizacao' in unidade && unidade?.tipo_normatizacao && (
            <div className="mt-4 has-text-grey">
              Normatização: {unidade.tipo_normatizacao.nome} n°{' '}
              {unidade.numero_normatizacao} de{' '}
              <FormatarData timestamp={unidade.data_normatizacao} dateOnly />
            </div>
          )}

          <article className="message is-orange mb-0 mt-4">
            <div className="message-body has-text-dark">
              {unidade.cnpj && (
                <p>
                  <strong>CNPJ</strong>{' '}
                  <span className="is-family-monospace">
                    {unidade.cnpj.indexOf('.') < 0
                      ? maskCnpj.apply(unidade.cnpj)
                      : unidade.cnpj}
                  </span>
                </p>
              )}
              {unidade?.responsavel?.nome && (
                <p>
                  <strong>Responsável</strong> {unidade.responsavel.nome}
                </p>
              )}
              <div className="mt-2 columns mb-0 is-size-7">
                {unidade.contatos.length > 0 && (
                  <div className="column is-half pb-0">
                    <strong>Contato</strong>
                    <br />
                    {unidade.contatos.map((contato) => (
                      <p
                        key={contato.id}
                      >{`${contato.tipo_contato.nome}: ${contato.valor}`}</p>
                    ))}
                  </div>
                )}
                {unidade.endereco && (
                  <div className="column pb-0">
                    <strong>Endereço</strong>
                    <br />
                    {`${unidade.endereco.rua}, ${unidade.endereco.numero}`}
                    <br />
                    {unidade.endereco.complemento && (
                      <>
                        <span>{`${unidade.endereco.complemento}`}</span>
                        <br />
                      </>
                    )}
                    Bairro {`${unidade.endereco.bairro}`} - CEP{' '}
                    {maskCep.apply(unidade.endereco.cep)}
                    <br />
                    {`${unidade.endereco.municipio.nome}, ${unidade.endereco.municipio.estado.sigla}`}
                  </div>
                )}
              </div>
            </div>
          </article>

          {exibirEstrutura && (
            <>
              <div className="mt-5">
                <Link
                  to={`/unidade/${id}/organograma`}
                  target="_blank"
                  className="button is-orange is-outlined"
                >
                  <i className="fas fa-sitemap mr-1"></i> Organograma
                </Link>
              </div>

              <h5 className="title is-5 mb-0 mt-5">
                Estrutura Organizacional{' '}
                <small className="is-size-7 has-text-orange is-family-monospace">
                  ({filhos.length})
                </small>
              </h5>

              <p className="mb-2">
                Lista de unidades e departamentos subordinados.
              </p>

              <div className="field">
                <p className="control has-icons-left">
                  <input
                    type="text"
                    className="input"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    placeholder="Filtrar"
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-search"></i>
                  </span>
                </p>
              </div>

              <FiltroCaracteristica updateFilters={setCaracteristicas} />

              <div className="lista-estrutura">
                {filhos.map((filho, key) => (
                  <ItemEstrutura data={filho} key={key} />
                ))}
                {filhos.length < 1 && (
                  <div className="has-text-danger">
                    <p>Nenhum registro encontrado na busca por "{search}".</p>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
