import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import '../assets/busca.css'
import Logo from '../assets/logo-governo-ro.png'

import api from '../services/api'
import ItemEstrutura from '../components/ItemEstrutura'
import { FiltroCaracteristica } from '../components/FiltroCaracteristica'

export default function Estrutura() {
  const [busca, setBusca] = useState(false)
  const [buscando, setBuscando] = useState(false)
  const [ultimaBusca, setUltimaBusca] = useState(false)
  const [resultados, setResultados] = useState(false)
  const [caracteristicas, setCaracteristicas] = useState('')

  async function buscaGeral(searchTerm) {
    setBuscando(true)
    const retorno = await api.get(`/busca?s=${searchTerm}&${caracteristicas}`)
    setResultados(retorno.data)
    setBuscando(false)
    setUltimaBusca(searchTerm)
  }

  useEffect(() => {
    document.body.classList.add('busca')
    document.documentElement.classList.add('busca')
  }, [])

  return (
    <>
      <div className='container busca'>
        <div className='columns'>
          <div className='column is-half-widescreen is-offset-one-quarter-widescreen is-three-fifths-tablet is-offset-one-fifth-tablet is-full-mobile'>
            <div className='has-text-centered'>
              <img
                src={Logo}
                alt='Governo de Rondônia'
                className='is-fullwidth pt-6 mt-6 mb-4 logo-busca'
              />
              <h1 className='title is-5 is-uppercase'>
                Estrutura Organizacional
              </h1>
            </div>

            <div className='field my-5'>
              <form
                onSubmit={e => {
                  e.preventDefault()
                  if (busca && busca.length >= 2) return buscaGeral(busca)
                }}>
                <p className='control has-icons-left'>
                  <input
                    type='text'
                    autoFocus
                    className='input is-medium busca-geral'
                    onChange={e => setBusca(e.target.value)}
                  />
                  <span className='icon is-small is-left'>
                    <i className='fas fa-search'></i>
                  </span>
                </p>
              </form>
            </div>

            <div className='has-text-centered px-6'>
              <FiltroCaracteristica updateFilters={setCaracteristicas} />
            </div>

            <div className='has-text-centered'>
              <button
                disabled={
                  (busca && busca.length >= 2) || buscando ? false : true
                }
                className={`button is-link ${buscando ? 'is-loading' : null}`}
                onClick={() => buscaGeral(busca)}>
                <i className='fas fa-search mr-1'></i> Buscar Estrutura
              </button>
              <Link to='/estrutura' className='button is-outlined ml-3'>
                <i className='fas fa-sitemap mr-1'></i> Explorar Estrutura
              </Link>
            </div>

            {busca && resultados && (
              <>
                <div className='is-size-7 has-text-centered my-4'>
                  <p className='has-text-weight-bold'>Legenda</p>
                  <p>
                    <span className='icon is-small has-text-link' title='Lei'>
                      <i className='is-size-7 fas fa-gavel mr-1'></i>
                    </span>{' '}
                    Lei
                    <span
                      className='icon is-small has-text-orange ml-3'
                      title='Decreto'>
                      <i className='is-size-7 fas fa-gavel mr-1'></i>
                    </span>{' '}
                    Decreto
                    <span
                      className='icon is-small has-text-dark ml-3'
                      title='Ato Interno'>
                      <i className='is-size-7 fas fa-gavel mr-1'></i>
                    </span>{' '}
                    Ato Interno
                  </p>
                </div>

                <div className='lista-estrutura my-4'>
                  <p className='mb-2'>
                    <span className='is-family-monospace has-text-weight-bold'>
                      {resultados.length}
                    </span>{' '}
                    resultados encontrados na busca por "
                    <span className='has-text-weight-bold'>{ultimaBusca}</span>
                    ".
                  </p>
                  {resultados.map((resultado, key) => (
                    <ItemEstrutura data={resultado} key={key} />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
