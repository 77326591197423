import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import StringMask from 'string-mask'

import api from '../services/api'
import ItemEstrutura from '../components/ItemEstrutura'
import { FiltroCaracteristica } from '../components/FiltroCaracteristica'

export default function Instituicao() {
  const { id } = useParams()
  const [timer, setTimer] = useState(null)
  const [instituicao, setInstituicao] = useState(false)
  const [unidades, setUnidades] = useState(false)
  const [caracteristicas, setCaracteristicas] = useState([])

  const maskCnpj = new StringMask('00.000.000/0000-00')

  const loadInstituicao = useCallback(async () => {
    const retorno = await api.get('/instituicoes/' + id + '/detalhes')
    setInstituicao(retorno.data)
  }, [id])

  useEffect(() => {
    loadInstituicao()
  }, [loadInstituicao])

  const loadUnidades = useCallback(async () => {
    const retorno = await api.get(
      `/instituicoes/${id}/estrutura-direta?${caracteristicas}`
    )
    setUnidades(retorno.data)
  }, [id, caracteristicas])

  useEffect(() => {
    loadUnidades()
  }, [loadUnidades])

  async function searchUnidades(searchTerm = null) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setTimer(
      setTimeout(async () => {
        const retorno = await api.get(
          `/instituicoes/${id}/estrutura-direta?s=${searchTerm}&${caracteristicas}`
        )
        setUnidades(retorno.data)
      }, 500)
    )
  }

  return (
    <>
      {instituicao && (
        <>
          <div className='tag is-link is-uppercase has-text-weight-bold mb-2'>
            Instituição
          </div>
          {instituicao.sigla && <> {instituicao.sigla}</>}
          <h1 className='title is-4 mb-0'>{instituicao.pessoa.nome}</h1>
          <div className='mb-4'>
            {'tipo_normatizacao' in instituicao &&
              instituicao.tipo_normatizacao && (
                <span
                  className={`icon is-small has-text-${
                    instituicao.tipo_normatizacao.nome === 'Lei'
                      ? 'link'
                      : instituicao.tipo_normatizacao.nome === 'Decreto'
                      ? 'orange'
                      : 'dark'
                  }`}
                  title={instituicao.tipo_normatizacao?.nome}>
                  <i className='fas fa-gavel is-size-7'></i>
                </span>
              )}
          </div>
          <article className='message is-link mb-0'>
            <div className='message-body has-text-dark'>
              {instituicao.pessoa_juridica.cnpj && (
                <p>
                  <strong>CNPJ</strong>{' '}
                  <span className='is-family-monospace'>
                    {instituicao.pessoa_juridica.cnpj.indexOf('.') < 0
                      ? maskCnpj.apply(instituicao.pessoa_juridica.cnpj)
                      : instituicao.pessoa_juridica.cnpj}
                  </span>
                </p>
              )}
              {instituicao.responsavel && instituicao.responsavel.nome && (
                <p>
                  <strong>Responsável</strong> {instituicao.responsavel.nome}
                </p>
              )}
            </div>
          </article>

          {unidades && (
            <>
              <h5 className='title is-5 mb-0 mt-5'>
                Unidades{' '}
                <small className='is-size-7 has-text-orange is-family-monospace'>
                  ({unidades.length})
                </small>
              </h5>
              <p className='mb-2'>Lista de unidades subordinadas</p>

              <div className='field'>
                <p className='control has-icons-left'>
                  <input
                    type='text'
                    className='input'
                    onChange={e => searchUnidades(e.target.value)}
                    placeholder='Filtrar'
                  />
                  <span className='icon is-small is-left'>
                    <i className='fas fa-search'></i>
                  </span>
                </p>
              </div>

              <FiltroCaracteristica
                departamentos={false}
                updateFilters={setCaracteristicas}
              />

              <div className='field'></div>

              <div className='lista-estrutura'>
                {unidades.map((unidade, key) => (
                  <ItemEstrutura data={unidade} key={key} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
