import React, { useEffect, useState } from 'react'

export const FiltroCaracteristica = ({
  unidades = true,
  departamentos = true,
  updateFilters
}) => {
  const valorPadrao = false
  const [gestora, setGestora] = useState(valorPadrao)
  const [orcamentaria, setOrcamentaria] = useState(valorPadrao)
  const [administrativa, setAdministrativa] = useState(valorPadrao)
  const [colegiada, setColegiada] = useState(valorPadrao)
  const [staff, setStaff] = useState(valorPadrao)
  const [operacional, setOperacional] = useState(valorPadrao)
  const [tatico, setTatico] = useState(valorPadrao)
  const [estrategico, setEstrategico] = useState(valorPadrao)

  useEffect(() => {
    const selecionados = []
    if (unidades && gestora) selecionados.push('gestora=1')
    if (unidades && orcamentaria) selecionados.push('orcamentaria=1')
    if (administrativa) selecionados.push('administrativa=1')
    if (colegiada) selecionados.push('colegiada=1')
    if (staff) selecionados.push('staff=1')
    if (operacional) selecionados.push('operacional=1')
    if (tatico) selecionados.push('tatico=1')
    if (estrategico) selecionados.push('estrategico=1')
    updateFilters(selecionados.join('&'))
  }, [
    unidades,
    departamentos,
    gestora,
    orcamentaria,
    administrativa,
    colegiada,
    staff,
    operacional,
    tatico,
    estrategico,
    updateFilters
  ])

  return (
    <div className='filtros mb-5'>
      <p className='mb-2'>Filtrar por características</p>
      {unidades && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={gestora}
            onChange={e => setGestora(previousValue => !previousValue)}
          />
          {` `}Gestora
        </label>
      )}
      {unidades && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={orcamentaria}
            onChange={e => setOrcamentaria(previousValue => !previousValue)}
          />
          {` `}Orçamentaria
        </label>
      )}
      {(unidades || departamentos) && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={administrativa}
            onChange={e => setAdministrativa(previousValue => !previousValue)}
          />
          {` `}Administrativa
        </label>
      )}
      {(unidades || departamentos) && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={colegiada}
            onChange={e => setColegiada(previousValue => !previousValue)}
          />
          {` `}Colegiada
        </label>
      )}
      {(unidades || departamentos) && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={staff}
            onChange={e => setStaff(previousValue => !previousValue)}
          />
          {` `}Staff
        </label>
      )}
      {departamentos && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={operacional}
            onChange={e => setOperacional(previousValue => !previousValue)}
          />
          {` `}Operacional
        </label>
      )}
      {departamentos && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={tatico}
            onChange={e => setTatico(previousValue => !previousValue)}
          />
          {` `}Tático
        </label>
      )}
      {departamentos && (
        <label className='checkbox mr-3'>
          <input
            type='checkbox'
            checked={estrategico}
            onChange={e => setEstrategico(previousValue => !previousValue)}
          />
          {` `}Estratégico
        </label>
      )}
    </div>
  )
}
