import React, { useState, useEffect } from 'react';

import api from '../services/api';
import ItemEstrutura from '../components/ItemEstrutura';

export default function Estrutura() {
  const [instituicoes, setInstituicoes] = useState(false);

  useEffect(() => {
    async function loadInstituicoes() {
      const retorno = await api.get('/instituicoes');
      setInstituicoes(retorno.data);
    }
    loadInstituicoes();
  }, []);

  async function searchInstituicoes(term) {
    const retorno = await api.get('/instituicoes', {
      params: { limit: false, s: term },
    });
    setInstituicoes(retorno.data);
  }

  return (
    <>
      <h5 className='title is-5 mb-0'>
        Instituições{' '}
        <small className='is-size-7 has-text-link is-family-monospace'>
          ({instituicoes.total})
        </small>
      </h5>
      <p className='mb-2'>
        Lista de Instituições do Poder Executivo do Estado de Rondônia
      </p>

      <div className='field'>
        <p className='control has-icons-left'>
          <input
            type='text'
            className='input'
            onChange={(e) => searchInstituicoes(e.target.value)}
            placeholder='Filtrar'
          />
          <span className='icon is-small is-left'>
            <i className='fas fa-search'></i>
          </span>
        </p>
      </div>

      {!instituicoes && <p>Carregando...</p>}
      {instituicoes && (
        <div className='lista-estrutura'>
          {instituicoes.results.map((instituicao, key) => (
            <ItemEstrutura data={instituicao} key={key} />
          ))}
        </div>
      )}
    </>
  );
}
