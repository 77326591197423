import axios from 'axios'
import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
import { setupCache } from 'axios-cache-adapter'

localforage.defineDriver(memoryDriver)

const forageStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver
  ],
  name: 'organograma-ro'
})

const cache = setupCache({ maxAge: 15 * 60 * 1000, store: forageStore })

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  adapter: cache.adapter
})

api.interceptors.request.use(function (config) {
  config.headers.Authorization = process.env.REACT_APP_CDEV_KEY
  return config
})

api.interceptors.request.use(
  function (config) {
    window.$loading += 1
    document.getElementById('loading').setAttribute('rel', window.$loading)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (config) {
    window.$loading -= 1
    document.getElementById('loading').setAttribute('rel', window.$loading)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default api
