import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Template from './pages/Template';

import Busca from './pages/Busca';
import Estrutura from './pages/Estrutura';
import Instituicao from './pages/Instituicao';
import Unidade from './pages/Unidade';
import Unidade_Organograma from './pages/Unidade_Organograma';
import Departamento from './pages/Departamento';
import Departamento_Organograma from './pages/Departamento_Organograma';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Busca} />
        <Route
          path='/unidade/:id/organograma'
          component={Unidade_Organograma}
        />
        <Route
          path='/departamento/:id/organograma'
          component={Departamento_Organograma}
        />
        <Template>
          <Route path='/estrutura' component={Estrutura} />
          <Route path='/instituicao/:id' component={Instituicao} />
          <Route path='/unidade/:id' component={Unidade} />
          <Route path='/departamento/:id' component={Departamento} />
        </Template>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
