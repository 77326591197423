import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tree } from 'react-d3-tree';

import api from '../services/api';

class NodeLabel extends React.PureComponent {
  render() {
    const { className, nodeData } = this.props;
    return (
      <div className={className}>
        {nodeData.attributes.__tipo === 'unidade' && (
          <>
            <div className='tag is-orange is-uppercase is-size-7 has-text-weight-bold mb-2'>
              Unidade
            </div>
            {nodeData.attributes.sigla && (
              <div className='is-size-7'>{nodeData.attributes.sigla}</div>
            )}
            <h1 className='title is-6 mb-0'>
              {nodeData._children && (
                <>
                  {nodeData._collapsed ? (
                    <i className='fas fa-plus-circle is-size-7 mr-1'></i>
                  ) : (
                    <i className='fas fa-minus-circle is-size-7 mr-1'></i>
                  )}
                </>
              )}
              {nodeData.attributes.nome}
            </h1>
            <div className='mb-4'>
              {nodeData.attributes.tipo_normatizacao && (
                <span
                  className={`icon is-small has-text-${
                    nodeData.attributes.tipo_normatizacao.nome === 'Lei'
                      ? 'link'
                      : nodeData.attributes.tipo_normatizacao.nome === 'Decreto'
                      ? 'orange'
                      : 'dark'
                  }`}
                  title={nodeData.attributes.tipo_normatizacao?.nome}>
                  <i className='fas fa-gavel is-size-7'></i>
                </span>
              )}
              <span className='has-text-orange'>
                {nodeData.attributes.gestora && (
                  <span className='icon is-small' title='Unidade Gestora'>
                    <div className='fas fa-circle is-size-7'></div>
                  </span>
                )}
                {nodeData.attributes.orcamentaria && (
                  <span className='icon is-small' title='Unidade Orçamentária'>
                    <div className='fas fa-exclamation-circle is-size-7'></div>
                  </span>
                )}
                {nodeData.attributes.administrativa && (
                  <span
                    className='icon is-small'
                    title='Unidade Administrativa'>
                    <div className='far fa-circle is-size-7'></div>
                  </span>
                )}
                {nodeData.attributes.colegiada && (
                  <span className='icon is-small' title='Unidade Colegiada'>
                    <div className='fas fa-dot-circle is-size-7'></div>
                  </span>
                )}
                {nodeData.attributes.staff && (
                  <span className='icon is-small' title='Unidade Staff'>
                    <div className='far fa-dot-circle is-size-7'></div>
                  </span>
                )}
              </span>
            </div>
          </>
        )}
        {nodeData.attributes.__tipo === 'departamento' && (
          <>
            <div className='tag is-dark is-uppercase is-size-7 has-text-weight-bold mb-2'>
              Departamento
            </div>
            {nodeData.attributes.sigla && (
              <div className='is-size-7'>{nodeData.attributes.sigla}</div>
            )}
            <h1 className='title is-6 mb-0'>
              {nodeData._children && (
                <>
                  {nodeData._collapsed ? (
                    <i className='fas fa-plus-circle is-size-7 mr-1'></i>
                  ) : (
                    <i className='fas fa-minus-circle is-size-7 mr-1'></i>
                  )}
                </>
              )}
              {nodeData.attributes.nome}
            </h1>
            <div className='mb-4'>
              {nodeData.attributes.tipo_normatizacao && (
                <span
                  className={`icon is-small has-text-${
                    nodeData.attributes.tipo_normatizacao.nome === 'Lei'
                      ? 'link'
                      : nodeData.attributes.tipo_normatizacao.nome === 'Decreto'
                      ? 'orange'
                      : 'dark'
                  }`}
                  title={nodeData.attributes.tipo_normatizacao?.nome}>
                  <i className='fas fa-gavel is-size-7'></i>
                </span>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default function Unidade_Organograma() {
  const { id } = useParams();
  const [organograma, setOrganograma] = useState(false);

  useEffect(() => {
    async function loadUnidade() {
      async function loadOrganograma(unidade) {
        await api.get('/unidades/' + id + '/organograma').then((result) => {
          setOrganograma(result.data);
        });
      }
      await api.get('/unidades/' + id + '/detalhes').then((result) => {
        loadOrganograma(result.data);
      });
    }
    loadUnidade();
  }, [id]);

  if (!organograma) return <p>Carregando...</p>;
  if (organograma)
    return (
      <div id='organograma'>
        <Tree
          data={organograma}
          allowForeignObjects={true}
          separation={{ siblings: 1, nonSiblings: 2 }}
          pathFunc={'elbow'}
          initialDepth={2}
          nodeSize={{ x: 250, y: 175 }}
          translate={{
            x: window.innerWidth * 0.45,
            y: window.innerHeight * 0.33,
          }}
          nodeLabelComponent={{
            render: <NodeLabel className='myLabelComponentInSvg' />,
            foreignObjectWrapper: {
              x: 16,
              y: 8,
            },
          }}
          orientation={'vertical'}
        />
      </div>
    );
}
