import React from 'react'
import { Link } from 'react-router-dom'

export default function ItemEstrutura({ data }) {
  return (
    <div
      className={
        'message ' +
        ('departamento_id' in data === true &&
        'instituicao_id' in data === false
          ? 'is-dark'
          : 'administracao_direta' in data
          ? 'is-link'
          : 'is-orange') +
        ' mb-2'
      }>
      <div className='message-body px-3 py-2'>
        <Link
          className='has-text-dark'
          to={
            '/' +
            ('departamento_id' in data === true &&
            'instituicao_id' in data === false
              ? 'departamento'
              : 'administracao_direta' in data
              ? 'instituicao'
              : 'unidade') +
            '/' +
            data.id
          }>
          {'tipo_normatizacao' in data && data.tipo_normatizacao && (
            <div
              className={`icon is-small has-text-${
                data.tipo_normatizacao.nome === 'Lei'
                  ? 'link'
                  : data.tipo_normatizacao.nome === 'Decreto'
                  ? 'orange'
                  : 'dark'
              } mr-1`}>
              <i className='fas fa-gavel is-size-7'></i>
            </div>
          )}
          {data.sigla_composta && (
            <>
              <strong>{data.sigla_composta}</strong>
              <br />
            </>
          )}
          {'pessoa' in data ? data.pessoa.nome : data.nome}

          <div className='has-text-orange'>
            {data?.gestora && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Gestora
              </span>
            )}
            {data?.orcamentaria && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Orçamentária
              </span>
            )}
            {(data?.administrativa || data?.unidade_administrativa) && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Administrativa
              </span>
            )}
            {data?.colegiada && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Colegiada
              </span>
            )}
            {data?.staff && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Staff
              </span>
            )}
            {data?.operacional && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Operacional
              </span>
            )}
            {data?.tatico && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Tática
              </span>
            )}
            {data?.estrategico && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in data ? 'orange' : 'dark'
                }`}>
                Estratégica
              </span>
            )}
          </div>
        </Link>
      </div>
    </div>
  )
}
