import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'

import Logo from '../assets/logo-governo-ro.png'
import api from '../services/api'

function Template({ location, children }) {
  const [darkMode, setDarkMode] = useState(
    document.body.classList.contains('dark')
  )
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    document.body.classList.remove('busca')
    document.documentElement.classList.remove('busca')
  }, [])

  useEffect(() => {
    async function loadMenu() {
      if (location.pathname === '/estrutura') return setMenu(false)
      const url = location.pathname.split('/')
      const retorno = await api.get(
        `/${
          url[1] === 'unidade'
            ? 'unidades'
            : url[1] === 'departamento'
            ? 'departamentos'
            : 'instituicoes'
        }/${url[2]}/caminho`
      )
      setMenu(retorno.data)
    }

    loadMenu()
  }, [location])

  return (
    <>
      <div className='container'>
        <div className='columns'>
          <div className='column is-one-quarter pr-5'>
            <img
              src={Logo}
              alt='Governo de Rondônia'
              className='is-fullwidth mt-5 mb-2'
            />
            <div className='message has-background-white mt-3 mb-0'>
              <div className='message-body px-2 py-1'>
                <Link to='/' className='is-block'>
                  <span className='icon mr-1'>
                    <i className='fas fa-search'></i>
                  </span>
                  Busca geral
                </Link>
              </div>
            </div>
            {location.pathname !== '/estrutura' && (
              <div className='message has-background-white mt-2 mb-0'>
                <div className='message-body px-2 py-1'>
                  <Link
                    to='/estrutura'
                    className='is-block'
                    style={{ textAlign: 'left' }}>
                    <span className='icon mr-1'>
                      <i className='fas fa-arrow-left'></i>
                    </span>
                    Início da Estrutura
                  </Link>
                </div>
              </div>
            )}
            <hr />
            {menu && (
              <>
                <div className='nav-pathway'>
                  <p className='mb-1'>Estrutura explorada</p>
                  {menu.map((item, key) => {
                    return !item ? null : (
                      <div
                        key={key}
                        className={`message is-${
                          item.__tipo === 'unidade'
                            ? 'orange'
                            : item.__tipo === 'departamento'
                            ? 'dark'
                            : 'link'
                        } has-background-${
                          key === 0
                            ? item.__tipo === 'unidade'
                              ? 'orange'
                              : item.__tipo === 'departamento'
                              ? 'dark'
                              : 'link'
                            : 'white'
                        } mb-1`}>
                        <div className='message-body px-2 py-1'>
                          <Link
                            to={`/${item.__tipo}/${item.id}`}
                            title={`${
                              'pessoa' in item ? item.pessoa.nome : item.nome
                            }`}
                            className={`has-text-${
                              key === 0 ? 'white' : 'dark'
                            }`}>
                            {key > 0 && (
                              <span className='icon is-small mr-1'>
                                <i className='fas fa-chevron-circle-up'></i>
                              </span>
                            )}
                            <span className='sigla is-size-6'>
                              {item.sigla
                                ? item.sigla
                                : 'pessoa' in item
                                ? item.pessoa.nome
                                : item.nome}
                            </span>
                            {item.sigla && (
                              <div className='is-wrapped is-size-7'>
                                {'pessoa' in item
                                  ? item.pessoa.nome
                                  : item.nome}
                              </div>
                            )}
                          </Link>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <hr />
              </>
            )}
            {location.pathname === '/estrutura' && (
              <>
                <div className='notification is-link mb-3'>
                  Selecione uma instituição para explorar sua estrutura
                  organizacional.
                </div>
                <hr />
              </>
            )}

            <p className='mt-3 is-size-7 has-text-weight-bold'>Contraste</p>
            <div className='buttons has-addons mb-0'>
              <button
                onClick={() => {
                  if (darkMode) {
                    localStorage.setItem('dark-mode', false)
                    document.body.classList.remove('dark')
                    document.documentElement.classList.remove('dark')
                    setDarkMode(false)
                  }
                }}
                className={`button is-${darkMode ? 'light' : 'link'} is-small`}>
                <i className={`fa${darkMode ? 'r' : 's'} fa-sun`}></i>
              </button>
              <button
                onClick={() => {
                  if (!darkMode) {
                    localStorage.setItem('dark-mode', true)
                    document.body.classList.add('dark')
                    document.documentElement.classList.add('dark')
                    setDarkMode(true)
                  }
                }}
                className={`button is-${
                  darkMode ? 'link' : 'outlined'
                } is-small`}>
                <i className={`fa${darkMode ? 's' : 'r'} fa-moon`}></i>
              </button>
            </div>

            <div className='is-size-7 mt-3'>
              <p className='mt-3 has-text-weight-bold'>Legenda</p>
              <p>
                <span className='icon is-small has-text-link'>
                  <i className='is-size-7 fas fa-gavel mr-1'></i>
                </span>{' '}
                Lei
              </p>
              <p>
                <span className='icon is-small has-text-orange'>
                  <i className='is-size-7 fas fa-gavel mr-1'></i>
                </span>{' '}
                Decreto
              </p>
              <p>
                <span className='icon is-small has-text-dark'>
                  <i className='is-size-7 fas fa-gavel mr-1'></i>
                </span>{' '}
                Ato Interno
              </p>
            </div>
          </div>
          <div className='column pl-5'>
            <h1 className='title is-4 is-uppercase mt-5 mb-4'>
              Estrutura Organizacional
            </h1>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Template)
