import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import StringMask from 'string-mask'

import api from '../services/api'
import ItemEstrutura from '../components/ItemEstrutura'
import { FormatarData } from '../components/FormatarData'
import { FiltroCaracteristica } from '../components/FiltroCaracteristica'

export default function Departamento() {
  const { id } = useParams()
  const [departamento, setDepartamento] = useState(false)
  const [filhos, setFilhos] = useState([])
  const [search, setSearch] = useState(null)
  const [caracteristicas, setCaracteristicas] = useState('')

  const maskCep = new StringMask('00.000-000')

  const loadDepartamento = useCallback(async () => {
    const retorno = await api.get(`/departamentos/${id}/detalhes`)
    setDepartamento(retorno.data)
  }, [id])

  useEffect(() => {
    setSearch('')
    loadDepartamento()
  }, [loadDepartamento])

  const loadFilhos = useCallback(async () => {
    const retorno = await api.get(
      `/departamentos/${id}/estrutura-direta?s=${search}&limit=false&${caracteristicas}`
    )
    setFilhos(retorno.data)
  }, [id, caracteristicas, search])

  useEffect(() => {
    loadFilhos()
  }, [loadFilhos])

  return (
    <>
      {departamento && (
        <>
          <div className="tag is-dark is-uppercase has-text-weight-bold mb-2">
            Departamento
          </div>
          {departamento.sigla && <> {departamento.sigla}</>}
          <h1 className="title is-4 mb-0">{departamento.nome}</h1>
          <div className="mt-1">
            {departamento?.unidade_administrativa && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in departamento ? 'orange' : 'dark'
                }`}
              >
                Administrativa
              </span>
            )}
            {departamento?.colegiada && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in departamento ? 'orange' : 'dark'
                }`}
              >
                Colegiada
              </span>
            )}
            {departamento?.staff && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in departamento ? 'orange' : 'dark'
                }`}
              >
                Staff
              </span>
            )}
            {departamento?.operacional && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in departamento ? 'orange' : 'dark'
                }`}
              >
                Operacional
              </span>
            )}
            {departamento?.tatico && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in departamento ? 'orange' : 'dark'
                }`}
              >
                Tática
              </span>
            )}
            {departamento?.estrategico && (
              <span
                className={`tag mr-1 is-light is-${
                  'instituicao_id' in departamento ? 'orange' : 'dark'
                }`}
              >
                Estratégica
              </span>
            )}
          </div>

          {'tipo_normatizacao' in departamento &&
            departamento?.tipo_normatizacao && (
              <div className="mt-4 has-text-grey">
                Normatização: {departamento.tipo_normatizacao.nome} n°{' '}
                {departamento.numero_normatizacao} de{' '}
                <FormatarData
                  timestamp={departamento.data_normatizacao}
                  dateOnly
                />
              </div>
            )}

          <article className="message is-dark mb-0 mt-4">
            <div className="message-body has-text-dark">
              {departamento.responsavel && departamento.responsavel.nome && (
                <p>
                  <strong>Responsável</strong> {departamento.responsavel.nome}
                </p>
              )}
              <div className="mt-2 columns mb-0 is-size-7">
                {departamento.contatos.length > 0 && (
                  <div className="column is-half pb-0">
                    <strong>Contato</strong>
                    <br />
                    {departamento.contatos.map((contato) => (
                      <p
                        key={contato.id}
                      >{`${contato.tipo_contato.nome}: ${contato.valor}`}</p>
                    ))}
                  </div>
                )}
                {departamento.endereco && (
                  <div className="column pb-0">
                    <strong>Endereço</strong>
                    <br />
                    {`${departamento.endereco.rua}, ${departamento.endereco.numero}`}
                    <br />
                    {departamento.endereco.complemento && (
                      <>
                        <span>{`${departamento.endereco.complemento}`}</span>
                        <br />
                      </>
                    )}
                    Bairro {`${departamento.endereco.bairro}`} - CEP{' '}
                    {maskCep.apply(departamento.endereco.cep)}
                    <br />
                    {`${departamento.endereco.municipio.nome}, ${departamento.endereco.municipio.estado.sigla}`}
                  </div>
                )}
              </div>
            </div>
          </article>

          <>
            <div className="mt-5">
              <Link
                to={`/departamento/${id}/organograma`}
                target="_blank"
                className="button is-dark is-outlined"
              >
                <i className="fas fa-sitemap mr-1"></i> Organograma
              </Link>
            </div>

            <h5 className="title is-5 mb-0 mt-5">
              Estrutura Organizacional{' '}
              <small className="is-size-7 has-text-dark is-family-monospace">
                ({filhos.length})
              </small>
            </h5>

            <p className="mb-2">Lista de departamentos subordinados</p>

            <div className="field">
              <p className="control has-icons-left">
                <input
                  type="text"
                  className="input"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  placeholder="Filtrar"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-search"></i>
                </span>
              </p>
            </div>

            <FiltroCaracteristica updateFilters={setCaracteristicas} />

            <div className="lista-estrutura">
              {filhos.map((filho, key) => (
                <ItemEstrutura data={filho} key={key} />
              ))}
            </div>
          </>
        </>
      )}
    </>
  )
}
