import React from 'react';
import Routes from './routes';

import 'bulma/css/bulma.css';
import './assets/app.css';
import './assets/app-dark.css';
import './assets/mobile.css';

const App = () => <Routes />;

export default App;
